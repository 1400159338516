import { useEffect } from 'react';

const useJiraWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://jsd-widget.atlassian.com/assets/embed.js";
    script.setAttribute("data-key", "0c763cdd-ee8d-414e-8ddc-3b0e8998f60c")
    script.setAttribute("data-jsd-embedded", "")
    script.setAttribute("data-base-url", "https://jsd-widget.atlassian.com")
    script.addEventListener('load', (event) => {
        window.document.dispatchEvent(new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true
        }));
      }
    );

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
};

export default useJiraWidget;
