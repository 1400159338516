/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubOrg } from '../models/SubOrg';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SubOrganizationsService {
    /**
     * Create a group
     * Create a group under the parent organization. Only an admin user can perform this action.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the parent organization
     * @param requestBody
     * @returns any Group created successfully
     * @throws ApiError
     */
    public static createSubOrg(
        authorization: string,
        orgId?: string,
        requestBody?: {
            sub_org_name: string;
        },
    ): CancelablePromise<{
        message?: string;
        sub_org_id?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/org/group',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Group with the same name already exists`,
                403: `Permission denied`,
            },
        });
    }
    /**
     * Update a group
     * Update a group under the parent organization. Only an admin user can perform this action.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the parent organization
     * @param requestBody
     * @returns any Group updated successfully
     * @throws ApiError
     */
    public static updateSubOrg(
        authorization: string,
        orgId?: string,
        requestBody?: SubOrg,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/org/group',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Group with the same name already exists`,
                403: `Permission denied`,
            },
        });
    }
    /**
     * List all groups
     * List all groups under the parent organization.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the parent organization
     * @param addDeviceCount Add device count
     * @returns SubOrg List of groups
     * @throws ApiError
     */
    public static listSubOrgs(
        authorization: string,
        orgId?: string,
        addDeviceCount?: boolean,
    ): CancelablePromise<Array<SubOrg>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/group',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'add_device_count': addDeviceCount,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }
    /**
     * Get groups
     * Get groups under the parent organization.
     * @param orgId ID of the organization
     * @param id ID of the parent organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns SubOrg Group
     * @throws ApiError
     */
    public static getSubOrg(
        orgId: string,
        id: string,
        authorization: string,
    ): CancelablePromise<SubOrg> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/group/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }
    /**
     * Move a device to a group
     * This endpoint allows an admin to move a device to a specific group
     * @param orgId Organization ID
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Device successfully moved
     * @throws ApiError
     */
    public static postDevicesGroup(
        orgId: string,
        authorization: string,
        requestBody: {
            /**
             * ID of the group
             */
            sub_org_id: string;
            /**
             * Serial number of the device
             */
            serial: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/devices/group',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal server error`,
            },
        });
    }
}
