import React, { useEffect, useState } from "react";
import { auth, prod } from "@src/Defines";
import { AppBar, Box, Container, IconButton } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Link } from "react-router-dom";


const drawerWidth = 240;
const navItems = [
  { title: 'Features', link: "/features", hideOnProd: false },
  { title: 'Blog', link: "/blog", hideOnProd: false },
  { title: 'About Us', link: "/about-us", hideOnProd: false },
  { title: 'Pricing', link: "/pricing", hideOnProd: false },
];

const NavBar = () => {
  const [token, setToken] = useState<{ token: string | undefined }>({ token: undefined });


  useEffect(() => {
    setToken({ token: auth() })
  }, []);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ pt: 3 }}>

      <Link to="/" style={{ paddingLeft: '10px' }}>
        <img src="/images/logo-blue.png" height="34" alt=""/>
      </Link>

      <List>
        {navItems.map((item) =>
          <ListItem key={item.link} disablePadding hidden={item.hideOnProd && prod()}>
            <Link to={item.link} style={{ paddingTop: 16 }}>
              <Typography variant="button" sx={{ px: 2, fontWeight: "bold" }}>
                {item.title}
              </Typography>
            </Link>
          </ListItem>
        )}

        {!token.token &&
          <ListItem key="login" disablePadding>
            <Link to="/login" style={{ paddingTop: 16 }}>
              <Typography variant="button" sx={{ px: 2, fontWeight: "bold" }}>Login</Typography>
            </Link>
          </ListItem>
        }
        {token.token &&
          <ListItem key="dash" disablePadding>
            <Link to="/dash" style={{ paddingTop: 16 }}>
              <Typography variant="button" sx={{ px: 2, fontWeight: "bold" }}>Dashboard</Typography>
            </Link>
          </ListItem>
        }

      </List>
    </Box>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return <>
    <AppBar component="nav" sx={{ background: 'transparent', boxShadow: 'none' }}>
      <Container maxWidth="lg" sx={{ background: 'white', p: 2 }} disableGutters>
        <Toolbar disableGutters
                 sx={{ borderRadius: "32px", backgroundColor: "#112d4e", pl: 3, pr: 2 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}>
            <MenuIcon/>
          </IconButton>

          <Link className="logo" to="/" style={{ flexGrow: 1, paddingTop: 1 }}>
            <img src="/images/logo-light.png" height="44" alt=""/>
          </Link>

          <Box sx={{ display: { sm: 'none', xs: 'none', md: 'block' } }}>
            {navItems.map((item) =>
              <Link key={item.title} to={item.link} style={{ color: '#fff' }} hidden={item.hideOnProd && prod()}>
                <Typography variant="button" sx={{ px: 2, fontWeight: "bold" }}>
                  {item.title}
                </Typography>
              </Link>
            )}
          </Box>

          <Box sx={{ display: { sm: 'none', xs: 'none', md: 'block' } }}>
            {!token.token &&
              <Link to="/login" className="btn btn-success" style={{ marginLeft: 4 }}>
                <Typography variant="button" sx={{ px: 1, fontWeight: "bold" }}>Login</Typography>
              </Link>
            }
            {token.token &&
              <Link to="/dash" className="btn btn-success" style={{ marginLeft: 4 }}>
                <Typography variant="button" sx={{ px: 1, fontWeight: "bold" }}>Dashboard</Typography>
              </Link>
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    <nav>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  </>
}

export default NavBar;
