/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataTransferResponse } from '../models/DataTransferResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CloudFrontService {
    /**
     * setupcf
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any Update successful
     * @throws ApiError
     */
    public static getOrgSetupcf(
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/setupcf',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * getsignedurl
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Update successful
     * @throws ApiError
     */
    public static postOrgGetsignedurl(
        orgId: string,
        authorization: string,
        requestBody?: Record<string, any>,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/org/getsignedurl',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * getdlmetrics
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns DataTransferResponse Update successful
     * @throws ApiError
     */
    public static getOrgGetdlmetrics(
        authorization: string,
        orgId?: string,
    ): CancelablePromise<DataTransferResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/getdlmetrics',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
}
