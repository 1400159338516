/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ObjectStorageResponse } from '../models/ObjectStorageResponse';
import type { Org } from '../models/Org';
import type { OrgConfiguration } from '../models/OrgConfiguration';
import type { OrgProperties } from '../models/OrgProperties';
import type { OrgReporting } from '../models/OrgReporting';
import type { OrgSettings } from '../models/OrgSettings';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrganizationsService {
    /**
     * Get orgs list
     * Get orgs list. Only an super admin user can perform this action.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Org Successful
     * @throws ApiError
     */
    public static listOrgs(
        authorization: string,
    ): CancelablePromise<Array<Org>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/list',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }
    /**
     * Get org details
     * Get orgs list. Only an super admin user can perform this action.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns Org Successful
     * @throws ApiError
     */
    public static getOrgDetails(
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Org> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/details/{org_id}',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }
    /**
     * Update org details
     * Update orgs details. Only an super admin user can perform this action.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param requestBody
     * @returns any Update successful
     * @throws ApiError
     */
    public static putOrgDetails(
        authorization: string,
        orgId?: string,
        requestBody?: Org,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/org/details/{org_id}',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete org
     * Delete org. Only an super admin user can perform this action.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any Update successful
     * @throws ApiError
     */
    public static deleteOrgDetails(
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/org/details/{org_id}',
            path: {
                'org_id': orgId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Get org config
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @returns OrgConfiguration Update successful
     * @throws ApiError
     */
    public static getOrgConfig(
        orgId: string,
        authorization: string,
        subOrgId?: string,
    ): CancelablePromise<OrgConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/config',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Update user config
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @param requestBody
     * @returns any Update successful
     * @throws ApiError
     */
    public static putOrgConfig(
        orgId: string,
        authorization: string,
        subOrgId?: string,
        requestBody?: OrgConfiguration,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/org/config',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get org reporting
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @returns OrgReporting Update successful
     * @throws ApiError
     */
    public static getOrgReporting(
        orgId: string,
        authorization: string,
        subOrgId?: string,
    ): CancelablePromise<OrgReporting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/reporting',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Update user config
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @param requestBody
     * @returns any Update successful
     * @throws ApiError
     */
    public static putOrgReporting(
        orgId: string,
        authorization: string,
        subOrgId?: string,
        requestBody?: OrgReporting,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/org/reporting',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get org settings
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @returns OrgSettings Update successful
     * @throws ApiError
     */
    public static getOrgSettings(
        orgId: string,
        authorization: string,
        subOrgId?: string,
    ): CancelablePromise<OrgSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/settings',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Update user settings
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @param isGlobal Sent to all devices
     * @param requestBody
     * @returns any Update successful
     * @throws ApiError
     */
    public static putOrgSettings(
        orgId: string,
        authorization: string,
        subOrgId?: string,
        isGlobal?: boolean,
        requestBody?: OrgSettings,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/org/settings',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'is_global': isGlobal,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get org properties
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns OrgProperties Update successful
     * @throws ApiError
     */
    public static getOrgProperties(
        orgId: string,
        authorization: string,
    ): CancelablePromise<OrgProperties> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/properties',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Update org properties
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Update successful
     * @throws ApiError
     */
    public static putOrgProperties(
        orgId: string,
        authorization: string,
        requestBody?: OrgProperties,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/org/properties',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get s3usage
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the group
     * @returns ObjectStorageResponse Update successful
     * @throws ApiError
     */
    public static getOrgS3Usage(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<ObjectStorageResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/s3usage',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Update user config
     * @param orgId ID of the organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Update successful
     * @throws ApiError
     */
    public static putOrgS3Usage(
        orgId: string,
        authorization: string,
        requestBody?: OrgConfiguration,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/org/s3usage',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Update user rights
     * Promote a user to the 'admin' role in the organization. Only an admin user can perform this action.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the group
     * @param requestBody
     * @returns any Promotion successful
     * @throws ApiError
     */
    public static postUsersRights(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        requestBody?: {
            /**
             * Email of the user to be promoted
             */
            target_email: string;
            rights: Record<string, any>;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/rights',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Remove a group
     * Remove a group given the organization ID and the group ID. Only the admin of the organization has the permissions to remove a group.
     * @param subOrgId ID of the group
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any Group removed successfully
     * @throws ApiError
     */
    public static removeSubOrg(
        subOrgId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/org/group',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Stats devices in an organization and its groups
     * This endpoint retrieves the devices in the given organization and its groups
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-org
     * @returns any Devices retrieved successfully
     * @throws ApiError
     */
    public static getDevicesStats(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        disconnected: number;
        connected: number;
        total: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/stats',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }
}
