import * as React from 'react';
import { Suspense, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Backdrop, Breadcrumbs, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { AccountCircle, AdminPanelSettings, Logout, MoreVert } from "@mui/icons-material";
import { auth, Authorization, Features, Me, org, target, targetName } from "@src/Defines";
import { Bucket, User, UsersService } from "@src/rest";
import secureLocalStorage from "react-secure-storage";
import { googleLogout } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import DocumentsPage from "@src/content/dash/pages/documents/DocumentsPage";
import moment from "moment";
import { DashDrawer, sidebar } from "@src/content/dash/components/DashDrawer";

const drawerWidth = 240;
const DeviceOverview = React.lazy(() => import("../content/dash/pages/devices/DeviceOverview"));
const DeviceDetails = React.lazy(() => import("../content/dash/pages/devices/DeviceDetails"));
const DashboardPage = React.lazy(() => import("@src/content/dash/pages/dashboard/DashboardPage"));
const UserDetails = React.lazy(() => import("../content/dash/pages/users/UserDetails"));
const Billing = React.lazy(() => import("../content/dash/pages/billing/Billing"));
const BucketsConfiguration = React.lazy(() => import("../content/dash/pages/administration/buckets-configuration/BucketsConfiguration"));
const Orgs = React.lazy(() => import("../content/dash/pages/administration/orgs/Orgs"));
const OrgSetup = React.lazy(() => import("../content/dash/pages/administration/orgs/OrgSetup"));
const SmartAgentSessions = React.lazy(() => import("../content/dash/pages/administration/smart-agent-sessions/SmartAgentSessions"));
const SmartAgentMessages = React.lazy(() => import("../content/dash/pages/administration/smart-agent-sessions/SmartAgentMessages"));

const DevicesPage = React.lazy(() => import("../content/dash/pages/devices/DevicesPage"));
const UsersPage = React.lazy(() => import("../content/dash/pages/users/UsersPage"));
const GroupsPage = React.lazy(() => import("@src/content/dash/pages/groups/GroupsPage"));

const FlagsPage = React.lazy(() => import("../content/dash/pages/feature-flags/FeatureFlagsPage"));
const PackagesPage = React.lazy(() => import("../content/dash/pages/packages/PackagesPage"));
const JobsPage = React.lazy(() => import("../content/dash/pages/jobs/JobsPage"));
const RolloutsPage = React.lazy(() => import("../content/dash/pages/rollouts/RolloutsPage"));
const MessagingPage = React.lazy(() => import("../content/dash/pages/messaging/MessagingPage"));
const AlertsPage = React.lazy(() => import("../content/dash/pages/alerts/AlertsPage"));
const ConfigurationPage = React.lazy(() => import("../content/dash/pages/org-config/ConfigurationPage"));
const LogcatAnalysis = React.lazy(() => import("@src/content/dash/pages/devices/details/debug/LogcatAnalysis"));
const BugreportAnalysis = React.lazy(() => import("@src/content/dash/pages/devices/details/debug/BugreportAnalysis"));

const IssuesPage = React.lazy(() => import("../content/dash/pages/issues/IssuesPage"));
const DeviceAgentPage = React.lazy(() => import("../content/dash/pages/smart-agent/SmartAgentPage"));
const RolloutOverview = React.lazy(() => import("../content/dash/pages/rollouts/RolloutOverview"));
const AlertOverview = React.lazy(() => import("../content/dash/pages/alerts/AlertOverview"));
const IssueOverview = React.lazy(() => import("../content/dash/pages/issues/IssueOverview"));
const ApplicationOverview = React.lazy(() => import("../content/dash/pages/packages/ApplicationOverview"));

const routes = [

  { path: "/", element: DevicesPage },

  { path: "/devices", element: DevicesPage },
  { path: "/users", element: UsersPage },
  { path: "/groups", element: GroupsPage },
  { path: "/flags", element: FlagsPage },
  { path: "/packages", element: PackagesPage },
  { path: "/packages/:package_name", element: ApplicationOverview },

  { path: "/jobs", element: JobsPage },
  { path: "/rollouts", element: RolloutsPage },
  { path: "/messaging", element: MessagingPage },
  { path: "/alerts", element: AlertsPage },
  { path: "/issues", element: IssuesPage },
  { path: "/configuration", element: ConfigurationPage },

  { path: "/dashboard", element: DashboardPage },
  { path: "/billing", element: Billing },
  { path: "/chat", element: DeviceAgentPage },

  { path: "/user/:email", element: UserDetails },

  { path: "/device/:serial/overview", element: DeviceOverview },
  { path: "/device/:serial", element: DeviceDetails },
  { path: "/device/:serial/logcat/:uuid", element: LogcatAnalysis },
  { path: "/device/:serial/bugreport/:uuid", element: BugreportAnalysis },

  { path: "/rollout/:uuid", element: RolloutOverview },
  { path: "/alert/:id", element: AlertOverview },
  { path: "/issue/:hash", element: IssueOverview },

  { path: "/api", element: DocumentsPage },

  { path: "/buckets", element: BucketsConfiguration, admin: true },
  { path: "/orgs", element: Orgs, admin: true },
  { path: "/smart-agent-sessions", element: SmartAgentSessions, admin: true },
  { path: "/smart-agent-messages/:channel", element: SmartAgentMessages, admin: true },
  { path: "/org-setup/:org_id", element: OrgSetup, admin: true },
];


const loading = (<Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
  <div className="custom-loader"></div>
</Backdrop>);

export default function DashLayout() {

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {
    const jiraWidget = document.getElementById("jsd-widget")
    if (jiraWidget) jiraWidget.style.display = "none"

  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [me, setMe] = useState<User | undefined>(undefined);
  const [superAdmin, setSuperAdmin] = useState(false)
  const [bucket, setBucket] = useState<Bucket | undefined>(undefined)
  const [secondsLeft, setSecondsLeft] = useState(0)


  useEffect(() => {
    UsersService.getUsersMe(auth(), target()).then(response => {
      console.log(response)
      if (!response.bucket) {
        logout()
        return
      }

      setMe(response.user)
      setSuperAdmin(response.super_admin)
      setBucket(response.bucket)
      setSecondsLeft(response.trial_seconds_left)

      secureLocalStorage.setItem(Me, response.user)
      secureLocalStorage.setItem(Features, response.bucket?.billedFeatures ?? {})
    }, err => {
      console.error(err)
    })
  }, []);

  const timeLeft = () => {
    const duration = moment.duration(secondsLeft, 'seconds');
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours() % 24);
    return duration.days() >= 1 ? days + ' days' : hours + ' hours'
  }

  const getHeader = () => {
    const { pathname } = location;

    if (pathname.startsWith('/dash/device/')) {
      return pathname.split('/')[3];
    }

    for (const item of sidebar) {
      for (const it of item.items) {
        if (pathname.startsWith(`/dash/${it.to.slice(0, -1)}`)) {
          return it.text;
        }
      }
    }

    return '';
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };


  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMenuItems = <>
    <MenuItem onClick={() => logout()} sx={{ py: 0 }}>
      <Logout sx={{ pr: 2, width: '40px', color: 'gray' }}/><p>Logout</p>
    </MenuItem>

    {superAdmin &&
      <MenuItem onClick={() => buckets()} sx={{ py: 0 }}>
        <AdminPanelSettings sx={{ pr: 2, width: '40px', color: 'gray' }}/><p>Buckets</p>
      </MenuItem>
    }
    {superAdmin &&
      <MenuItem onClick={() => orgs()} sx={{ py: 0 }}>
        <AdminPanelSettings sx={{ pr: 2, width: '40px', color: 'gray' }}/><p>Organizations</p>
      </MenuItem>
    }
    {superAdmin &&
      <MenuItem onClick={() => smartAgentSessions()} sx={{ py: 0 }}>
        <AdminPanelSettings sx={{ pr: 2, width: '40px', color: 'gray' }}/><p>Smart Agent Sessions</p>
      </MenuItem>
    }
  </>

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {renderMenuItems}
    </Menu>
  );

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {renderMenuItems}
    </Menu>
  );

  const buckets = () => {
    navigate('/dash/buckets')
    handleMenuClose()
  }

  const orgs = () => {
    navigate('/dash/orgs')
    handleMenuClose()
  }

  const smartAgentSessions = () => {
    navigate('/dash/smart-agent-sessions')
    handleMenuClose()
  }

  const logout = () => {
    googleLogout()
    UsersService.logoutUser(auth()).then(response => {
        console.log(response)
        secureLocalStorage.removeItem(Authorization)
        window.location.reload()
      }, err => {
        console.log(err)
        secureLocalStorage.removeItem(Authorization)
        window.location.reload()
      }
    )
  };


  return <Box sx={{ display: 'flex' }}>

    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    <CssBaseline/>
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar sx={{ minHeight: '48px' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon/>
        </IconButton>

        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Breadcrumbs aria-label="breadcrumb" color="white">
            <Link color="inherit" to="/dash">
              <Typography variant="h6" color="white">{org()} {target() ? ` / ${targetName()}` : ``}</Typography>
            </Link>
            <Typography variant="h6" color="white" noWrap component="div">
              {getHeader()}
            </Typography>
          </Breadcrumbs>
        </Box>

        <Box sx={{ flexGrow: 1 }}/>

        <Box sx={{ pr: 5, display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
          <Link to={"billing"}>
            <Typography sx={{ color: 'white' }}>Subscription: <b>{bucket?.displayName}</b>
              {bucket?.trial &&
                <>
                  &nbsp;-&nbsp;
                  <b>{timeLeft()}</b> left
                </>
              }
            </Typography>
          </Link>
        </Box>

        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
          {me?.user_id}
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit">
            <AccountCircle/>
          </IconButton>
        </Box>

        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit">
            <MoreVert/>
          </IconButton>
        </Box>

      </Toolbar>
    </AppBar>
    {renderMobileMenu}
    {renderMenu}

    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <DashDrawer/>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open>
        <DashDrawer/>
      </Drawer>
    </Box>
    <Box
      component="main"
      sx={{
        height: 'calc(100vh - 16px)',
        flexGrow: 1,
        pl: 1,
        pr: 1,
        pb: 1,
        pt: 9,
        width: { sm: `calc(100% - ${drawerWidth}px)` }
      }}
    >
      <Suspense fallback={loading}>

        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (superAdmin || !route.admin) && (
                <Route key={idx} path={route.path} element={<route.element/>}/>
              )
            );
          })}
        </Routes>
      </Suspense>
    </Box>
  </Box>
}
