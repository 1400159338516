/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Alert = {
    id: string;
    comparison: string;
    name: string;
    event_type: Alert.event_type;
    severity?: Alert.severity;
    failed_device_ids?: Array<string>;
    /**
     * A history of failed device counts with timestamps
     */
    failed_device_history?: Array<{
        /**
         * The Unix timestamp when the record was created
         */
        timestamp: number;
        /**
         * The number of failed devices at the time of the record
         */
        failed_device_count: number;
    }>;
};
export namespace Alert {
    export enum event_type {
        NONE = 'none',
        EMAIL = 'email',
        PAGERDUTY = 'pagerduty',
        DATADOG = 'datadog',
    }
    export enum severity {
        ERROR = 'error',
        WARNING = 'warning',
        INFO = 'info',
        SUCCESS = 'success',
    }
}

