/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OTA } from '../models/OTA';
import type { ResponseMessage } from '../models/ResponseMessage';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OtaService {
    /**
     * Create an OTA for a Sub-Organizations
     * Create an OTA update for a specified group. The endpoint requires an array of software packages each containing 'name' and 'version'. The packages defined here will extend or override the software images defined in the parent org
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId Organization ID
     * @param subOrgId Suborg ID
     * @returns any OTA created successfully.
     * @throws ApiError
     */
    public static postOta(
        authorization: string,
        requestBody: {
            sw_packages: Array<{
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
                /**
                 * JSON config
                 */
                config?: string;
                /**
                 * Package Name
                 */
                package_name?: string;
            }>;
        },
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        /**
         * Presigned URLs for the OTA software packages
         */
        presigned_urls?: Array<string>;
        /**
         * Errors during the OTA creation, if any
         */
        errors?: Array<string>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ota',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified organization or group could not be found.`,
            },
        });
    }
    /**
     * Delete an OTA from a Group
     * Delete an OTA update for a specified group. The endpoint requires a software package containing 'name' and 'version'.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId Organization ID
     * @param subOrgId Suborg ID
     * @returns ResponseMessage OTA deleted successfully.
     * @throws ApiError
     */
    public static deleteOta(
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ota',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified software or organization could not be found.`,
            },
        });
    }
    /**
     * Confirm system OTA uploaded
     * Delete an OTA update for a specified group. The endpoint requires a software package containing 'name' and 'version'.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId Organization ID
     * @param subOrgId Suborg ID
     * @returns ResponseMessage OTA confirmed successfully.
     * @throws ApiError
     */
    public static postOtaConfirm(
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ota/confirm',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified software or organization could not be found.`,
            },
        });
    }
    /**
     * List all OTAs for the group
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Organization ID
     * @param subOrgId Suborg ID
     * @param countIssues Count issues
     * @param countRollouts Count rollouts
     * @returns OTA List of OTAs
     * @throws ApiError
     */
    public static getOtaApplications(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        countIssues?: boolean,
        countRollouts?: boolean,
    ): CancelablePromise<Array<OTA>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ota/applications',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'count_issues': countIssues,
                'count_rollouts': countRollouts,
            },
            errors: {
                404: `Group not found`,
            },
        });
    }
    /**
     * List all OTAs for the group
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Organization ID
     * @param subOrgId Suborg ID
     * @param countRollouts Count rollouts
     * @returns OTA List of OTAs
     * @throws ApiError
     */
    public static getOtaSystem(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        countRollouts?: boolean,
    ): CancelablePromise<Array<OTA>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ota/system',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'count_rollouts': countRollouts,
            },
            errors: {
                404: `Group not found`,
            },
        });
    }
    /**
     * Get S3 url to upload apk
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Organization ID
     * @param subOrgId Suborg ID
     * @returns any OTA created successfully.
     * @throws ApiError
     */
    public static getOtaUrl(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        /**
         * Presigned URLs for the OTA software packages
         */
        url: string;
        /**
         * S3 path
         */
        path: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ota/url',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The specified organization or group could not be found.`,
            },
        });
    }
    /**
     * Retrieve information from an APK file and create app OTA
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId Organization ID
     * @param subOrgId Suborg ID
     * @returns any Successful response
     * @throws ApiError
     */
    public static postOtaAdd(
        authorization: string,
        requestBody: {
            s3Path: string;
            name: string;
        },
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ota/add',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Generate SW OTA Download URL for an organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Software package details
     * @param orgId Organization ID
     * @param subOrgId Suborg ID
     * @returns any Success. Download URL is returned.
     * @throws ApiError
     */
    public static postOtaDownload(
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        /**
         * Download URL for the software package
         */
        download_url: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ota/download',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The requested software package could not be found.`,
            },
        });
    }
    /**
     * Install app
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Software package details
     * @param orgId Organization ID
     * @param subOrgId Suborg ID
     * @returns ResponseMessage Success. Download URL is returned.
     * @throws ApiError
     */
    public static postOtaInstall(
        authorization: string,
        requestBody: {
            sw_package: OTA;
        },
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ota/install',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The requested software package could not be found.`,
            },
        });
    }
    /**
     * Uninstall app
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Software package details
     * @param orgId Organization ID
     * @param subOrgId Suborg ID
     * @returns ResponseMessage Success. Download URL is returned.
     * @throws ApiError
     */
    public static postOtaUninstall(
        authorization: string,
        requestBody: {
            sw_package: {
                /**
                 * Software name
                 */
                name: string;
                /**
                 * Software version
                 */
                version: string;
            };
        },
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ota/uninstall',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
                401: `Unauthorized. The provided authorization token is missing, invalid, or expired.`,
                404: `Not Found. The requested software package could not be found.`,
            },
        });
    }
}
