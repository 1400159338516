/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MiscService {
    /**
     * Subscribe to updates
     * Subscribes a user to updates using their provided email address.
     * @param requestBody
     * @returns any Subscription successful
     * @throws ApiError
     */
    public static subscribeUpdates(
        requestBody: {
            email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/misc/subscribe-updates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Unsubscribe from updates
     * Unsubscribe a user from updates using their email address.
     * @param requestBody
     * @returns any Unsubscription successful
     * @throws ApiError
     */
    public static unsubscribeUpdates(
        requestBody: {
            email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/misc/unsubscribe-updates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Subscription not found`,
                500: `Internal server error`,
            },
        });
    }
}
