import React from "react";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"


const filterInternalPaths = (swaggerSpec: any) => {
  const filteredSpec = { ...swaggerSpec };

  Object.keys(filteredSpec.paths).forEach((path) => {
    const pathItem = filteredSpec.paths[path];
    Object.keys(pathItem).forEach((method) => {
      const operation = pathItem[method];
      if (operation.tags && (operation.tags[0] === 'Misc' || operation.tags[0] === 'Billing' || operation.tags[0] === 'Public' || operation.tags[0] === 'Payments')) {
        delete pathItem[method];
      }
    });
  });

  return filteredSpec;
};


const swaggerConfig = {
  spec: filterInternalPaths(require(`@src/swagger-${process.env["REACT_APP_STAGE"]}.json`)),
};


const DocumentsPage = () => <SwaggerUI {...swaggerConfig} />

export default DocumentsPage;
