/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Issue } from '../models/Issue';
import type { IssuesMetrics } from '../models/IssuesMetrics';
import type { Reboot } from '../models/Reboot';
import type { RebootMetrics } from '../models/RebootMetrics';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class IssuesService {
    /**
     * Get org issues
     * @param orgId ID of the organization
     * @param timeFrom Date From
     * @param timeTo Date To
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @param offset Offset
     * @param limit Limit
     * @returns Issue Get issues successful
     * @throws ApiError
     */
    public static getIssues(
        orgId: string,
        timeFrom: string,
        timeTo: string,
        authorization: string,
        subOrgId?: string,
        offset?: string,
        limit?: string,
    ): CancelablePromise<Array<Issue>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/issues',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'offset': offset,
                'limit': limit,
                'time_from': timeFrom,
                'time_to': timeTo,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get org issue
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param hash hash of the issue
     * @returns Issue Get issues successful
     * @throws ApiError
     */
    public static getIssue(
        authorization: string,
        hash: string,
    ): CancelablePromise<Issue> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/issue/{hash}',
            path: {
                'hash': hash,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get org reboot
     * @param orgId ID of the organization
     * @param timeFrom Date From
     * @param timeTo Date To
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @param offset Offset
     * @param limit Limit
     * @returns Reboot Get issues successful
     * @throws ApiError
     */
    public static getReboot(
        orgId: string,
        timeFrom: string,
        timeTo: string,
        authorization: string,
        subOrgId?: string,
        offset?: string,
        limit?: string,
    ): CancelablePromise<Array<Reboot>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reboot',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'offset': offset,
                'limit': limit,
                'time_from': timeFrom,
                'time_to': timeTo,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get org reboot metrics
     * @param orgId ID of the organization
     * @param timeFrom Date From
     * @param timeTo Date To
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @returns RebootMetrics Get issues successful
     * @throws ApiError
     */
    public static getRebootMetrics(
        orgId: string,
        timeFrom: string,
        timeTo: string,
        authorization: string,
        subOrgId?: string,
    ): CancelablePromise<RebootMetrics> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reboot/metrics',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'time_from': timeFrom,
                'time_to': timeTo,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get org issues metrics
     * @param orgId ID of the organization
     * @param timeFrom Date From
     * @param timeTo Date To
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @returns IssuesMetrics Get issues successful
     * @throws ApiError
     */
    public static getIssuesMetrics(
        orgId: string,
        timeFrom: string,
        timeTo: string,
        authorization: string,
        subOrgId?: string,
    ): CancelablePromise<IssuesMetrics> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/issues/metrics',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'time_from': timeFrom,
                'time_to': timeTo,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get org reboot count
     * @param orgId ID of the organization
     * @param timeFrom Date From
     * @param timeTo Date To
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @returns any Get issues successful
     * @throws ApiError
     */
    public static getRebootCount(
        orgId: string,
        timeFrom: string,
        timeTo: string,
        authorization: string,
        subOrgId?: string,
    ): CancelablePromise<{
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reboot/count',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'time_from': timeFrom,
                'time_to': timeTo,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get org issues count
     * @param orgId ID of the organization
     * @param timeFrom Date From
     * @param timeTo Date To
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @returns any Get issues successful
     * @throws ApiError
     */
    public static getIssuesCount(
        orgId: string,
        timeFrom: string,
        timeTo: string,
        authorization: string,
        subOrgId?: string,
    ): CancelablePromise<{
        count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/issues/count',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'time_from': timeFrom,
                'time_to': timeTo,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Create jira issue
     * @param key key of the issue
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any Update successful
     * @throws ApiError
     */
    public static createJiraIssue(
        key: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        url?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/issues/jira',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'key': key,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * Get jira issue
     * @param key key of the issue
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @returns any Get successful
     * @throws ApiError
     */
    public static getJiraIssue(
        key: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<{
        url?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/issues/jira',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'key': key,
            },
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
}
