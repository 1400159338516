import secureLocalStorage from "react-secure-storage";

export var Authorization = "token"
export var Organization = "organization"
export var Me = "user"
export var Features = "features"
export var Target = "target"
export var TargetName = "target_name"

const parseJwt = (token: string) => {
  const decode = JSON.parse(atob(token.split('.')[1]));
  if (decode.exp * 1000 < new Date().getTime()) {
    secureLocalStorage.removeItem(Authorization)
    window.location.reload()
  }
};

export const auth = () => {
  const item = secureLocalStorage.getItem(Authorization) as string | null
  if (item) {
    parseJwt(item)
    return item
  }
  return ""
};

export const target = (): string | undefined => {
  const item = secureLocalStorage.getItem(Target) as string | null;
  return item ?? undefined
}

export const targetName = () => secureLocalStorage.getItem(TargetName) as string | null ?? "";

export const org = () => secureLocalStorage.getItem(Organization) as string | null ?? "";
export const user = () => secureLocalStorage.getItem(Me) as {
  message?: string;
  organization_id?: string;
  user_id?: string;
  rights?: {[key: string]: string}
};

export const rightKeys = ['devices', 'groups', 'jobs', 'alerts', 'flags', 'packages', 'rollouts', 'config', 'users', 'billing', 'issues']


export const prod = () => process.env["REACT_APP_STAGE"] === "prod"
