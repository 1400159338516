import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import 'react-toastify/dist/ReactToastify.css';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

let root = createRoot(document.getElementById("root") as HTMLElement);


const client = new ApolloClient({
  uri: `https://d3b7y5fchajv1i.cloudfront.net/cms/read/en-US`,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer a3a7e72aa7231fc3f69dd758af2331c19fc8d5d68a37adaf`
  },
});

root.render(
  <ApolloProvider client={client}>
    <App/>
  </ApolloProvider>
);
